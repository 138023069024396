export interface IThing {
  id?: number | null;
  title?: string;
  description?: string;
  availability?: string;
  status?: string;
  borrowed_id?: string;
  owner_id?: string;
  borrow_relationship?: {
    thing_id: string;
    borrower_name: string;
  }
}

export function getInitialThingData(): IThing {
  return {
    id: null,
    title: '',
    description: '',
    availability: '',
    status: '',
    borrowed_id: '',
    owner_id: '',
    borrow_relationship: {
      thing_id: '',
      borrower_name: ''
    }
  };
}