import { useEffect, useContext } from "react";
import {getRedirectResult} from 'firebase/auth'
import { UserContext } from "../../../contexts/user.context";
import { signInWithGoogleRedirect } from "../../../utils/firebase/firebase.utils";
import SignUpFormComponent from "../../sign-up-form/sign-up-form.component";

import {
  auth,
  createUserDocumentFromAuth
} from '../../../utils/firebase/firebase.utils'

const SignIn = () => {
  const { currentUser } = useContext(UserContext);

  useEffect( () => {
    async function getData() {
      // Get the response for the redirect that just happened
      // based on the auth
      const response = await getRedirectResult(auth);
      console.log(response);
      if (response) {
        const userDocRef = await createUserDocumentFromAuth(response.user)
      }
    }
    getData();
  }, []);

  return (
    <div>
    {
      currentUser!=null ?
        <>You're signed in as {currentUser.displayName}. Go explore something! </> :
        <>
          <h1>Welcome! Please Sign-in</h1>
          <button onClick={signInWithGoogleRedirect}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >Sign in with Google</button>
          <SignUpFormComponent />
        </>
    }
    </div>

  );
};

export default SignIn;