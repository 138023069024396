import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState, FormEvent, ChangeEvent } from 'react';
import axios from 'axios';
import {IThing} from "../../types/thingTypes";

interface InviteData {
    email: string;
    inviter_id: string;
}


const InviteForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const queryClient = useQueryClient();

    const mutation = useMutation<any, Error, InviteData, unknown>({
        mutationFn: async(newInvite: InviteData) => axios.post('http://localhost:3001/api/invite', newInvite),
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({queryKey: ['invites']});
        }
    });

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const newInvite = { email, inviter_id: '1a2b3c4d5e6f7g8h9i0j' };
            mutation.mutate(newInvite);

            setEmail('');
        } catch (error) {
            console.error('Failed to send invitation');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                        type="email"
                        value={email}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        name="email"
                        id="email"
                        className="inline-block w-2/5 rounded-md border-0 py-1.5 px-3 mr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                        required
                    />
                    <button
                        className='inline-block w-fit rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        type="submit">Send invite
                    </button>
                </div>
            </div>
        </form>
    );
}

export default InviteForm;
