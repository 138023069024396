import { useState } from "react";
import { auth, createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from "../../utils/firebase/firebase.utils";

const defaultFormFields = {
  displayName: '',
  email: '',
  password: '',
  confirmPassword: ''
}

const SignUpFormComponent = () => {
  console.log("*** SignUpFormComponent ***");

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, email, password, confirmPassword } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({...formFields, [name]: value});
  }

  // Generate a user document inside of the Firebase external service
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Confirm that the password/confirmation match
    if(password !== confirmPassword) {
      console.log("//// ERROR: Passwords didn't match!");
      alert("//// ERROR: Passwords didn't match!");
      return;
    }

    try {
      // Check to see if we have authenticated the user with the email/password
      const { user } = await createAuthUserWithEmailAndPassword(
        email,
        password
        );

      if(!user) {
        console.log("//// ERROR: Email or Password doesn't match the user");
        return;
      } else {
        console.log("//// SUCCESS! A valid user was created. Waiting for a user document.");
      }

      // Create a user document from what is returned
      // We can't use the shared version of this that's in UserContext because we want to
      // pass in the displayName for sign ups going through this way
      const userDocRef = await createUserDocumentFromAuth(user, { displayName });
      console.log(userDocRef);
      resetFormFields();

    } catch (error) {

      if(error.code === 'auth/email-already-in-use') {
        alert('That email is already in use');
      }
      console.log("!! User creation error: ", error);
    }
  }

  return (
    <div>
      <h1>Sign up with your email and password</h1>
      <form onSubmit={handleSubmit}>
        <label>Display Name</label><br/>
        <input className="input-thing shadow appearance-none border rounded mr-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' onChange={handleChange} name='displayName' value={displayName} required /><br/>
        <label>Email</label><br/>
        <input className="input-thing shadow appearance-none border rounded mr-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='email' onChange={handleChange} name='email' value={email} required /><br/>
        <label>Password</label><br/>
        <input className="input-thing shadow appearance-none border rounded mr-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='password' onChange={handleChange} name='password' value={password} required /><br/>
        <label>Confirm Password</label><br/>
        <input className="input-thing shadow appearance-none border rounded mr-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='password' onChange={handleChange} name='confirmPassword' value={confirmPassword} required /><br/>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type='submit'>Sign Up Now</button>
      </form>
    </div>
  )
}

export default SignUpFormComponent;