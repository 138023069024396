import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import {IThing} from "../../../types/thingTypes";
import StatusMenu from "../../menus/status-menu.component";
import { UserContext } from "../../../contexts/user.context";
import {getInitialThingData} from "../../../types/thingTypes";

// interface Props {
//     initialThingDataForUser: IThing[];
// }

const Things = () => {
    const queryClient = useQueryClient(); // What am I missing to use TanQuery?
    const fetchInitialThingData = async () => {
        const response = await fetch(`http://localhost:3001/things/`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        return result['things'] as IThing[];
    };

    const {
        data: initialThingDataForUser,
        isLoading: allThingsForUserLoading,
        isError: allThingsForUserError,
        error: allThingsForUserErrorDetails
    } = useQuery({
        queryKey: ['allThingsForUser'],
        queryFn: fetchInitialThingData,
    });

    const usePostThing = (newThing: IThing) => {
        return useMutation({
            mutationFn: async (newThing: IThing) => {
                const response = await fetch(`http://localhost:3001/things/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newThing),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                return result['things'] as IThing[];
            },
            onSuccess: () => {
                queryClient.invalidateQueries({queryKey: ['things']});
            },
            onSettled: () => {
                queryClient.invalidateQueries({queryKey: ['allThingsForUser']});
            },

        })
    };


    // const {isLoading, error, data} = useQuery<IThing[], Error>('userThingsCollection', async () => {
    //   const response = await fetch('http://localhost:3001/things');
    //     if (isLoading) {
    //       return <span>Loading...</span>
    //     }
    //
    //     if (error) {
    //       return <span>Error: {error.message}</span>
    //     }
    //
    //     return response.json();
    //   });
    //
    //
    //
    // const [recentThings, addThing] = useState <IThing[] | []>([]);
    // const [remoteThings, setRemoteThings] = useState<IThing[] | []>([]);
    // const [allUserThings, setAllUserThings] = useState<IThing[] | []>(remoteThings);
    // const [thingValue, setThingValue] = useState<string>("");
    // const { currentUser } = useContext(UserContext);

    // https://stackoverflow.com/questions/62382324/react-typescript-this-jsx-tags-children-prop-expects-a-single-child-of-type
    // const handleClick = async (e: React.MouseEvent<HTMLFormElement>) => {
    //   e.preventDefault();
    //   const addedValue = thingValue;
    //   const whichUserId = currentUser.uid;
    //
    //   if (addedValue != "") {
    //     const thingObject:IThing = {
    //       title: addedValue,
    //       availability:'pending',
    //       owner_id: whichUserId
    //     }
    //
    //     await fetch(`http://localhost:3001/things`,
    //       {
    //         method: 'POST',
    //         headers: {
    //           'Accept': 'application/json',
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(thingObject),
    //         cache: 'default'
    //       })
    //       .then(response => {
    //         return response.json()
    //       })
    //       .then((result) => {
    //         thingObject['id'] = result.thing_id;
    //         setAllUserThings([...allUserThings, thingObject]);
    //       })
    //       .catch(error => {
    //         console.error('Error:', error);
    //       });
    //   };
    //
    //   setThingValue("");
    // }

    // useEffect(() => {
    //   const whichUser = currentUser['uid'];
    //
    //   fetch(`http://localhost:3001/user/${whichUser}/things`)
    //     .then((response) => response.json())
    //     .then((result) => setRemoteThings(result.things));
    // }, [setAllUserThings]);
    //
    // useEffect( () => {
    //   setAllUserThings(remoteThings);
    // }, [remoteThings]);
    //
    // //TODO: Initial load yields multiple fetches

    const [thingValue, setThingValue] = useState<IThing>(getInitialThingData());
    let addThingMutation = usePostThing(thingValue);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        console.log("handleSubmit");
        if (thingValue) {
            console.log("thingValue", thingValue);
            setThingValue(thingValue);
            addThingMutation.mutate(thingValue);
        }
    }

    return (
        <div>
            <h1>My Things</h1>
            {allThingsForUserLoading && <p>Loading...</p>}
            {allThingsForUserError && <p>Error: {allThingsForUserErrorDetails.message}</p>}


            {initialThingDataForUser && (
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <p className="mt-2 text-sm text-gray-700">
                            A list of all my things.
                        </p>
                    </div>
                    <div className="w-3/4 text-right mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <form onSubmit={handleSubmit}>
                            <input onChange={(e) => setThingValue({...thingValue, title: e.currentTarget.value})}
                                   className="input-thing shadow appearance-none border rounded mr-3 py-2 px-3
                               text-gray-700 leading-tight focus:outline-none focus:shadow-outline inline-block"
                                   placeholder="Add something new">
                            </input>
                            <button value="button-add"
                                    className="inline-block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    type="submit">Add
                            </button>
                        </form>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Title
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {initialThingDataForUser.map((thing) => (
                                    <tr key={thing.id}>
                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                            <div className="flex items-center">
                                                <div className="h-11 w-11 flex-shrink-0">
                                                    <img className="h-11 w-11 rounded-full"
                                                         src={`https://picsum.photos/id/${Math.floor(Math.random() * 100) + 1}/100`}
                                                         alt=""/>

                                                </div>
                                                <div className="ml-4">
                                                <div className="font-medium text-gray-900">{thing.title}</div>
                                                    <div className="mt-1 text-gray-500">{thing.description}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500"><span className='bg-green-200 py-2 px-4 text-gray-800 rounded-3xl'>Available</span></td>
                                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <Link to={`/things/${thing.id}`} className='text-indigo-600 hover:text-indigo-900'>Edit</Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    )
};

export default Things;