import InviteForm from "./forms/invite-form.component";
import InvitesList from "./invites-list.component";
const Friends = () => {
  return (
    <div>
      <h1>My Friends</h1>
      <h2>Let's invite some friends!</h2>

        <InviteForm />
        <InvitesList />
    </div>
  )
}

export default Friends;