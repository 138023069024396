import React from 'react';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import axios from 'axios';

interface Invite {
    id: string;
    email: string;
    code: string;
    used: boolean;
}

interface ApiResponse {
    invites: Invite[];
}

const fetchInvites = async (): Promise<Invite[]> => {
    const response = await axios.get<ApiResponse>('http://localhost:3001/user/81293857/invites'); // TODO: Replace with actual user id
    console.log("***data: ", response.data);
    return response.data.invites;
}


const InvitesList: React.FC = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: invites, error, isLoading, isError } = useQuery<Invite[], Error>({
        queryKey: ['invites'],
        queryFn: fetchInvites
    });

    const handleCopyClick = async (inviteCode: string, event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();  // Prevent the default anchor link behavior
        const inviteLink = `http:localhost:3000/invite/${inviteCode}`; // Construct the full invite link
        // TODO: Replace with environment variable of the actual domain
        try {
            await navigator.clipboard.writeText(inviteLink); // Attempt to copy the invite link to the clipboard
            console.log('Invite link copied to clipboard!');
            // Display a confirmation message (use a toast or modal in a real application)
            alert('Invite link copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy:', err);
            // Display an error message (consider a more user-friendly notification method)
            alert('Failed to copy the link. Please try again.');
        }
    };

    const deleteInviteMutation = useMutation({
        mutationFn: (inviteId: string) => axios.delete(`http://localhost:3001/user/81293857/invites/${inviteId}`),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['invites']});
            navigate("/friends"); // or some feedback mechanism
        }
    });

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error instanceof Error ? error.message : "Unknown error"}</div>;

    return (
        <div>
            <ul>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Existing Invites</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Name
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {invites?.map((invite) => (
                                        <tr key={invite.id}>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Name TBD</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invite.email}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {invite.used ? "Joined" : "Pending"}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-0">
                                                <a href={`/invite/${invite.code}`}
                                                   onClick={(e) => handleCopyClick(invite.code, e)}
                                                   title={"Copy invite link to clipboard"}
                                                   className="px-2 inline-block text-indigo-600 hover:text-indigo-900">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                         className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"/>
                                                    </svg>
                                                </a>
                                                <a href="#"
                                                   className="px-2 inline-block text-indigo-600 hover:text-indigo-900"
                                                   title={"Resend invite"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                         className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                                    </svg>

                                                </a>
                                                <a href='#' onClick={() => deleteInviteMutation.mutate(invite.id)}
                                                    title={"Delete invite"}
                                                    className="px-2 inline-block text-indigo-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                                     className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
                                                </svg>
                                            </a>
                                        </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    );
}

export default InvitesList;
