import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";
import { IThing, getInitialThingData } from "../../../types/thingTypes";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {int} from "aws-sdk/clients/datapipeline";

const ThingDetailsComponent = () => {
  const queryClient = useQueryClient();

  const [editThing, setEditThing] = useState(false);
  const [shareModalVisibility, setShareModalVisibility] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  const fetchThingData = async () => {
    const response = await fetch(`http://localhost:3001/things/${params.thingId}`);
    if(!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    console.log("result thing", result['thing'] as IThing);
    const thing = result['thing'] as IThing;

    if (typeof params.thingId === 'string') {
      thing.id = thing.id || parseInt(params.thingId);
    } else {
      // Handle the case where params.thingId is not defined
      console.error('thingId is undefined');
      throw new Error('thingId must be defined');
    }

    return thing;
  };

  const { data: thingData, isLoading: thingLoading, isError: thingError, error: thingErrorDetails } = useQuery({
    queryKey: ['things', params.thingId],
    queryFn: fetchThingData,
  });

  const [localThingData, setLocalThingData] = useState(thingData);

  useEffect(() => {
    if (thingData) {
      setLocalThingData(thingData);
    }
  }, [thingData]);

  const updateThingData = async (thingId: string, newDetails: any) => {
    const response = await fetch(`http://localhost:3001/things/${thingId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(localThingData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };


  const { data: initialThingData, isLoading: allThingsLoading, isError: allThingsError, error: allThingsErrorDetails } = useQuery({
    queryKey: ['allThings'],
    queryFn: getInitialThingData,
  });

  const mutation = useMutation<any, Error, IThing, unknown>({
    mutationFn: (newDetails: IThing) => updateThingData(params.thingId!, newDetails),
    onError: (error: Error) => {
      // Handle errors here
      console.error('Error updating thing:', error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['things', params.thingId]});
      setEditThing(false);
    }
  });

  const handleSaveSharingInfo: React.MouseEventHandler<HTMLButtonElement> = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // CURRENT:
    // If record isn't an existing friend, Create a Person record first,
    // Return the ID, then attach it to this record
    // If the record is an existing friend, attach the person record to this borrow relationship
    addPerson()
    .then((result) => {
      console.log("RESULT: ", result);
      return addBorrowRelationship(result.borrower_id);
    })
  }

  const addBorrowRelationship = (borrowerId: any): void => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ thingId: params.thingId,
                                   borrowerName: shareToValue,
                                   borrowerId: borrowerId,
                                   ownerId: currentUser?.uid,
                                   status: 'borrowed'
                                  })
    };

    fetch('http://localhost:3001/borrows', requestOptions)
      .then(response => response.json());
      // TODO: Update thing status to borrowed on the screen
      // TODO: Show a success message
      // TODO: Prompt to invite the person to the app
  }

  const addPerson = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
    const personRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ first_name: shareToValue.split(" ")[0],
                             last_name: shareToValue.split(" ")[1]
                            })
    }

    fetch(`http://localhost:3001/people`, personRequestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to add a person');
      }
    })
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
  });

  }

  const deleteThing = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: params.thingId })
    };
    fetch(`http://localhost:3001/things/${params.thingId}`, requestOptions)
      .then(response => response.json())
      .then(() => navigate("/things"));
  }

  const toggleEdit = () => {
    setEditThing(!editThing);
  }

  const handleThingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const thingId = params.thingId;
    const currentData = queryClient.getQueryData(['things', thingId]);

    if(currentData) {
      const {name, value} = e.target;
      queryClient.setQueryData(['things', thingId],
          {...currentData, [name]: value
      });
    }

  };

  const returnThing = () => {
    if(thingData){
      const returnRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      };

      console.log("Returning borrower ID: ", thingData.borrowed_id)
      fetch(`http://localhost:3001/borrows/${thingData.borrowed_id}/return`, returnRequestOptions)
          .then(response => response.json())
          .then(() => mutation.mutate({...localThingData, ...{status: 'available'}})
          );

    }
  };

  const saveThing = () => {
    if(localThingData){
      console.log("localThingData: ", localThingData);
      mutation.mutate({...thingData});
    } else {
      console.error("Failed to save: Thing data is undefined");
    }
  };


  const [shareToValue, setShareToValue] = useState('');
  const handleShareToValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShareToValue(e.target.value);
  }

  const belongsToCurrentUser = (): boolean => {
    // return thingData.owner_id === currentUser.uid;
    return true; //temp
  }

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(thingData){
      setShareToValue(e.target.value)
      mutation.mutate({...thingData, ...{availability: e.target.value}});
    }
  }

  return (
    <div>
      { belongsToCurrentUser() &&
      <div className='p-3'>


        <a href='/things' className='pr-3'>Back to My things</a>
        <a href='#' onClick={()=>deleteThing()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block mr-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
        </svg></a>

        <a href='#' onClick={()=>toggleEdit()}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
              className="w-6 h-6 inline-block">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
          </svg>
        </a>
      </div>
      }

      {
        editThing ?
          <div className='rounded-2xl border-1 bg-gray-300 p-3'>
             Title: <input name="title" onChange={handleThingChange} className="block input-thing shadow appearance-none border rounded mr-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" defaultValue={localThingData?.title} />
             Description:  <input name="description" onChange={handleThingChange} className="block input-thing shadow appearance-none border rounded mr-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" defaultValue={localThingData?.description} />

            <select id='status-chooser' onChange={handleStatusChange} className="block rounded-2xl border-2 border-gray-600 px-5 py-1 my-3 rounded-2xl " defaultValue={thingData?.status}>
              <option id='status-choose-category' value='choose'>Choose Category</option>
              <option id='status-pending' value='pending'>Pending</option>
              <option id='status-unavailable' value='unavailable'>Unavailable</option>
              <option id='status-available' value='available'>Available</option>
            </select>

            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3' onClick={()=>saveThing()}>Save</button>
            <a href='#' onClick={()=>toggleEdit()}>Cancel</a>
          </div> :
            <div className='rounded-2xl border-1 bg-gray-300 p-3'>
            <h2>{localThingData?.title}</h2>
            <p><strong>Description: </strong>{localThingData?.description}</p>
            <p className='inline-block mr-1'>
              {localThingData?.availability === 'borrowed' &&
                <span className='flex w-3 h-3 bg-red-500 rounded-full'></span>
              }
              {localThingData?.availability === 'available' &&
                <span className='flex w-3 h-3 bg-green-500 rounded-full'></span>
              }
              {localThingData?.availability === 'pending' &&
                <span className='flex w-3 h-3 bg-gray-300 rounded-full'></span>
              }
              {localThingData?.availability === 'unavailable' &&
                <span className='flex w-3 h-3 bg-red-500 rounded-full'></span>
              }
            </p>
            <p className='inline-block'><span>{thingData?.availability}</span></p>
            <p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
              { belongsToCurrentUser() ? "Me" : "someone else" }
            </p>
          </div>
      }

      {/*{*/}
      {/*  thingData.status === 'borrowed' &&*/}
      {/*    <div>*/}
      {/*      <p>Borrowed by: {thingData.borrow_relationship.borrower_name}</p>*/}
      {/*      <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={()=>returnThing()}>Mark Returned</button>*/}
      {/*    </div>*/}
      {/*}*/}

      {/*{*/}
      {/*  thingData.status === 'available' &&*/}
      {/*  <div>*/}
      {/*    <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={()=>setShareModalVisibility(true)}>Share with someone</button>*/}
      {/*  </div>*/}
      {/*}*/}

      {
      shareModalVisibility ?
        <div className="p-3 border border-1 border-black rounded-2xl">
          <h3>Share something</h3>
          <p>
            Enter in the name (email optional for new users) of the person you're lending to: <br/>
            {/* TODO: Fuzzy find existing friends. If not, add a new person with optional email field */}
            Name (First and Last):
            <input type='text' value={shareToValue} onChange={handleShareToValueChange} id='shareToName' className='my-3 mx-3 w-1/4 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500' />
          </p>
          <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-3 w-auto' onClick={handleSaveSharingInfo}>Share</button>
          <a href='#' onClick={()=>setShareModalVisibility(false)}>Cancel</a>
        </div> :
        <></>
      }
    </div>
  )
}

export default ThingDetailsComponent;