import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';

interface InviteResponse {
    valid: boolean;
    email?: string;  // Assuming the API could send back an email if the invite is valid
}

const RedeemInvite: React.FC = () => {
    const { inviteCode } = useParams<{ inviteCode? : string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [valid, setValid] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    console.log("** code: ", inviteCode);

    useEffect(() => {
        axios.get<InviteResponse>(`http://localhost:3001/api/invite/${inviteCode}`)
            .then(response => {
                if (response.data.valid) {
                    setValid(true);
                    setEmail(response.data.email); // Capture email if available
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error verifying invite', error);
                setLoading(false);
            });
    }, [inviteCode]);

    if (loading) return <div>Loading...</div>;
    if (!valid) return <div>Sorry! This invite code is not valid or has expired.</div>;

    // If the invite is valid and email is provided, you can pass it to the signup page
    if (email) {
        return <Navigate to="/signup" state={{ email }} replace />;
    }

    // If no email is provided, just navigate normally
    return <Navigate to="/signup" replace />;
};

export default RedeemInvite;
