
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

const Home = () => {
  return (
      <div>
          <h1>Welcome to Beg N Borrow</h1>
          Simply a better way to borrow from your friend groups!

          <h3>Inventory</h3>
          Keep track of what you have and what you're willing to share.
          <a href='/things/'
                  className="block w-fit rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Start my inventory</a>
          <p>Here are some recent things created publicly...</p>
          <h3>Groups</h3>
          Start a group or find an existing group to in your area to share with. Maybe it's your local church, or a
          group of friends, or a neighborhood group.
          <a href='/groups/'
                  className="block w-fit rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Create my first group</a>
          <p>Here are some recent groups started in your area...</p>
          <h3>Share</h3>
          Share with your group. Share with your friends. Share with your neighbors. Share with your community. Share
          with your world.
          <a href='/share/'
                  className="block w-fit rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Get started sharing</a>
          <p>Estimated amount of money saved by people borrowing:</p>
          <p>$25,239</p>
      </div>
  )
}

export default Home;