import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import SignIn from './components/routes/sign-in/sign-in.component'
import Home from './components/routes/home/home.component';
import Navigation from './components/routes/navigation/navigation.component';
import Things from './components/routes/things/things.component'
import ThingDetailsComponent from "./components/routes/things/thing-details.component";
import Browse from './components/browse.component';
import Friends from './components/friends.component';
import { UserContext, UserProvider } from "./contexts/user.context";
import Bookmarks from "./components/routes/bookmarks/bookmarks.component";
import About from "./components/routes/about/about.component";
import Contact from "./components/routes/contact/contact.component";
import Dashboard from './components/routes/dashboard/dashboard.component';
import SignUpSplash from './components/routes/signupsplash.component';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import RedeemInvite from "./components/invites/redeem-invite.component";

const queryClient = new QueryClient()

function App() {

const { currentUser } = useContext(UserContext);

  return (
    <div>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path='/' element={<SignUpSplash />} />
        <Route path='/invite/:inviteCode' element={<RedeemInvite />} />
        <Route element={<Navigation />}>
          <Route path = '/home' element={<Home />} />
          <Route path = '/about' element={ <About /> } />
          <Route path = '/contact' element={ <Contact /> } />
          <Route path = '/dashboard' element={currentUser ? <Dashboard /> : <Navigate to="/sign-in" />} />
          <Route path = '/browse' element={currentUser ? <Browse /> : <Navigate to="/sign-in" />} />
          <Route path = '/bookmarks' element={currentUser ? <Bookmarks /> : <Navigate to="/sign-in" />} />
          <Route path = '/things' element={currentUser ? <Things /> : <Navigate to="/sign-in" />} />
          <Route path = '/things/:thingId' element={currentUser ? <ThingDetailsComponent /> : <Navigate to="/sign-in" />} />
          <Route path = '/friends' element={currentUser ? <Friends /> : <Navigate to="/sign-in" />} />
          <Route path = '/sign-in' element={<SignIn />} />
          <Route path = '/invite/:inviteCode' element={<RedeemInvite />} />
        </Route>
      </Routes>
      </QueryClientProvider>
    </UserProvider>
    </div>
  );
}

export default App;
