import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import { Link } from "react-router-dom";
import { BorrowChooser } from "../../menus/borrow-chooser.component";

import { CheckIcon, HandIcon, UserIcon } from '@heroicons/react/solid'

const timeline = [
    {
        id: 1,
        content: 'Requested',
        target: 'DeWalt Electric Drill',
        href: '#',
        date: 'Sep 20',
        datetime: '2020-09-20',
        icon: UserIcon,
        iconBackground: 'bg-gray-400',
    },
    {
        id: 2,
        content: 'Returned',
        target: 'Dyson Vacuum Cleaner',
        href: '#',
        date: 'Sep 22',
        datetime: '2020-09-22',
        icon: HandIcon,
        iconBackground: 'bg-blue-500',
    },
    {
        id: 3,
        content: 'Requested to borrow',
        target: 'Kelte 5 Man Tent',
        href: '#',
        date: 'Sep 28',
        datetime: '2020-09-28',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 4,
        content: 'Marked as returned',
        target: 'Ryobi Cordless Drill',
        href: '#',
        date: 'Sep 30',
        datetime: '2020-09-30',
        icon: HandIcon,
        iconBackground: 'bg-blue-500',
    },
    {
        id: 5,
        content: 'Added a comment',
        target: 'Best of Friends DVD',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const Dashboard = () => {
  const { currentUser } = useContext(UserContext);
  const [thingData, setThingData] = useState([]);

  console.log ("current User", currentUser.uid);

  useEffect(() => {
    fetch(`http://localhost:3001/user/${currentUser.uid}/borrows`)
      .then ((res) => res.json())
      .then((result) => setThingData(result.borrows));
  }, []);

  return (
      <div>

          <h1>Dashboard</h1>

          <h2>Borrows</h2>
          <BorrowChooser />

          <h2>Friend Activities</h2>
          <div className="flow-root">
              <ul role="list" className="-mb-8">
                  {timeline.map((event, eventIdx) => (
                      <li key={event.id}>
                          <div className="relative pb-8">
                              {eventIdx !== timeline.length - 1 ? (
                                  <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"/>
                              ) : null}
                              <div className="relative flex space-x-3">
                                  <div>
                  <span
                      className={classNames(
                          event.iconBackground,
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true"/>
                  </span>
                                  </div>
                                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                      <div>
                                          <p className="text-sm text-gray-500">
                                              {event.content}{' '}
                                              <a href={event.href} className="font-medium text-gray-900">
                                                  {event.target}
                                              </a>
                                          </p>
                                      </div>
                                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                          <time dateTime={event.datetime}>{event.date}</time>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </li>
                  ))}
              </ul>
          </div>
      </div>
  )
}

export default Dashboard;