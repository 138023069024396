import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Browse = () => {
  const [suggestedThings, setSuggestedThings] = useState([]);
  useEffect(() => {
    fetch(`http://localhost:3001/things`)
      .then((response) => response.json())
      .then((result) => setSuggestedThings(result.things));
  }, []);
  console.log("fetched things: ", suggestedThings)

  return (
    <div id="explore-results align-top m-0">
     <h1>Explore</h1>

      <h2>Find something in your community:</h2>
      <form>
        <input className="rounded-2xl w-1/3 border-2 border-gray-600 me-3"></input>
        <button className="bg-sky-400 px-5 py-1 me-3 rounded-2xl">Search</button>
        Filter by:
        <select className="rounded-2xl border-2 border-gray-600 px-5 py-1 me-3 rounded-2xl">
          <option>Choose Category</option>
          <option>Household</option>
          <option>Media</option>
          <option>Clothing</option>
        </select>
      </form>
      {
        suggestedThings.map(thing =>
        <div className='panel-explore-item h-60 w-1/4 inline-block m-3 rounded-2xl border-2 border-gray-600 overflow-hidden' key={thing.id}>
          <Link to={`/things/${thing.id}`}>{thing.title}<br/>
            <span me-8><img src='https://via.placeholder.com/400x400' className="w-auto h-auto" /></span>
          </Link>
        </div>
      )}
    </div>
  )
}


export default Browse;