
const Bookmarks = () => {
  return (
    <div>
      <h1>Favorites</h1>
      Here are your bookmarks!
    </div>
  )
}

export default Bookmarks;